<template>
  <div id="app" class="no-select">
    <div class="wrapper">
      <Hero @open-contact-card="openCard"/>
      <ContactCard 
      :isCardOpen="isCardOpen" 
      @open-contact-card="openCard"
      @post-message="openToaster"
      />
      <ThreeContent />
    </div>
    <Triangle/>
    <Toaster :messageOptions="messageOptions" />
  </div>
</template>

<script>
import ContactCard from '@/components/global/ContactCard';
import ThreeContent from '@/components/home/ThreeContent';
import Hero from '@/components/global/Hero';
import Toaster from '@/components/global/Toaster';
import layout from '@/json/layout.json'
import Triangle from '@/components/svg/Triangle';

export default {
  name: 'App',
  created () {
    this.loadLayout();
  },
  data() {
    return {
      isCardOpen: false,
      toasterActive: false,
      messageOptions: {
        isActive: false,
        message: ''
      }
    }
  },
  methods: {
    loadLayout() {
      for (const color in layout.colors) {
        document.documentElement.style.setProperty(layout.colors[color].variable, layout.colors[color].color);
      }
      for (const item in layout.layout) {
        document.documentElement.style.setProperty(layout.layout[item].variable, layout.layout[item].value);
      }
    },
    openToaster(options) {
      this.messageOptions = options;
    },
    openCard() {
      this.isCardOpen = !this.isCardOpen;
    }
  },
  components: {
    ContactCard,
    ThreeContent,
    Toaster,
    Hero,
    Triangle
  }
}
</script>

<style lang="scss">
$break-small: 900px;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500&display=swap');
* {
  touch-action: manipulation;
}
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .wrapper {
    width: 85%;
    margin: 0;

    max-width: var(--panel-width);
    @media screen and (max-width: $break-small) {
      max-width: unset;
      margin: 0 auto;
      padding: 0;
    }
  }
}
</style>
