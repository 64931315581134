<template>
<svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="500" height="500" fill="none"/>
    <path d="M265.032 131.745C265.032 72.2449 313.266 24.0109 372.766 24.0109V24.0109C432.266 24.0109 480.5 72.2449 480.5 131.745V131.745C480.5 191.244 432.266 239.478 372.766 239.478V239.478C313.266 239.478 265.032 191.244 265.032 131.745V131.745Z" fill="#006CFF"/>
    <!-- <path d="M265.032 131.745C265.032 72.2449 313.266 24.0109 372.766 24.0109V24.0109C432.266 24.0109 480.5 72.2449 480.5 131.745V131.745C480.5 191.244 432.266 239.478 372.766 239.478V239.478C313.266 239.478 265.032 191.244 265.032 131.745V131.745Z" fill="#F0CA44"/> -->
    <path d="M19.5 19V19C138.499 19 234.967 115.468 234.967 234.467V234.467H19.5V19Z" fill="black"/>
    <path d="M265.032 264.533H480.5V264.533C480.5 383.532 384.032 480 265.032 480V480V264.533Z" fill="black"/>
    <path d="M19.5 264.533H234.967V480V480C115.968 480 19.5 383.532 19.5 264.533V264.533Z" fill="black"/>
</svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
}

</style>