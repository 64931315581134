<template>
    <div class="header">
        <div class="header__title">
            <Submark class="header__submark" />
            <h1>Bixell</h1>
        </div>
        <Lines />
        <p class="header__hero-content">
            Building software for experiences. 
            <strong>Contact us to start your quote.</strong>
        </p>
        <button @click="openContactCard">
            Contact Now
            <Arrow />
        </button>
        <p class="header__content">
            Bixell works with a wide range of clients to build clever experiences on the web and native applications. Whether it be 3D commerce,  Games, or Augmented Reality, Bixell can service your need with our flexible customer centric philosophy.
        </p>
        <div class="header__ball"></div>
    </div>
</template>

<script>
import Lines from '@/components/svg/Lines';
import Submark from '@/components/svg/Submark';
import Arrow from '@/components/svg/Arrow';

    export default {
        components: {
            Arrow,
            Lines,
            Submark
        },
        methods: {
            openContactCard() {
                this.$emit("open-contact-card");
            }
        }
    }
</script>

<style lang="scss" scoped>
.header {
    $break-small: 900px;

    $submark-width: 23px;
    position: relative;
    max-width: 80%;
    margin: 70px auto;

    @media screen and (max-width: $break-small) {
        max-width: unset;
        margin: unset;
    }

    &__title {
        position: relative;
        width: auto;
        height: 60px;
        padding-left: #{$submark-width + 3px};
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
    }
    &__submark {
        width: $submark-width;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    h1 {
        font-family: Avalon;
        font-weight: 100;
        text-transform: lowercase;
        font-size: 40px;
        margin: 0;
        padding: 0;
        height: 30px;
    }
    &__hero-content {
        font-size: 28px;
        font-family: 'Roboto';
        font-weight: 100;
        margin: 20px 0 40px;
    }
    &__content {
        font-size: 18px;
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        font-weight: 300;
        margin: 50px 0 40px;
        line-height: 26px;
    }
    &__ball {
        mix-blend-mode: multiply;
        $ball-size: 216px;
        position: absolute;
        top: 30px;
        left: var(--panel-width);
        margin-left: -#{$ball-size / 2};
        width: $ball-size;
        height: $ball-size;
        border-radius: 50%;
        background-color: var(--secondary-color);
        @media screen and (max-width: $break-small) {
            position: fixed;
            left: unset;
            right: -#{$ball-size / 2};
            margin-left: unset;
        }
    }
    .fa-arrow-circle-o-right {
        float: right;
        margin-right: 20px;
    }
}
</style>