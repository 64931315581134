<template>
<div class="arrow">
     <svg width="61" height="48" viewBox="0 0 61 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 23.5C0 22.1193 1.11929 21 2.5 21H53.5C54.8807 21 56 22.1193 56 23.5C56 24.8807 54.8807 26 53.5 26H2.5C1.11929 26 0 24.8807 0 23.5Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9454 0.732233C35.9217 -0.244078 37.5046 -0.244078 38.4809 0.732233L59.6941 21.9454C60.6704 22.9217 60.6704 24.5047 59.6941 25.481L38.4809 46.6942C37.5046 47.6705 35.9217 47.6705 34.9454 46.6942C33.9691 45.7179 33.9691 44.1349 34.9454 43.1586L54.3908 23.7132L34.9454 4.26777C33.9691 3.29146 33.9691 1.70854 34.9454 0.732233Z" fill="white"/>
    </svg>
</div>

</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.arrow {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    float: right;
    svg {
        width: 15px;
    }
}
</style>