<template>
<svg width="69" height="223" viewBox="0 0 69 223" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.5 120.5L-115 2V325L66.5 120.5Z" stroke="black" stroke-width="2"/>
</svg>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
svg {
    position: absolute;
    left: 0;
    margin-top: -40px;
}
</style>