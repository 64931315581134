import Vue from 'vue'
import App from './App.vue'
import store from './store'
import '@/styles/base.scss';
import VueGtag from "vue-gtag";
var VueTouch = require('vue-touch')

Vue.use(VueTouch, {name: 'v-touch'})
Vue.use(VueGtag, {
  config: { id: 'G-32SB5F8XXZ' }
});
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
