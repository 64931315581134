<template>
    <div @click="options.isActive = !options.isActive" class="toaster" :class="{'toaster-active' : options.isActive}">
        <div class="toaster__text">
            {{options.message}}
            <i v-if="options.message === 'Message sent'" class="fa fa-envelope ml-1 toaster__icon" aria-hidden="true"></i>
        </div>

    </div>
</template>

<script>

    export default {
        props: ['messageOptions'],
        watch: {
            messageOptions(options) {
                this.options = options;
            },
            options: {
                handler(val){
                    setTimeout(() => {
                        val.isActive = false;
                    }, 4000);
                },
                deep: true
            }
        },
        data() {
            return {
                options: {
                    message: '',
                    isActive: false
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
$toaster-height: 80px;
$toaster-width: 220px;
$break-small: 900px;

.toaster {
    width: $toaster-width;
    height: $toaster-height;
    background-color: #fff;
    position: fixed;
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    left: calc(var(--panel-width) / 2);
    bottom: -$toaster-height;
    margin-left: -#{$toaster-width / 2};
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    
    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 85%;
    }
    &__icon {
        color: #000;
    }
}
.toaster-active {
    bottom: 20px;
}
@media screen and (max-width: $break-small) {
    .toaster {
        left: 50%;
        margin-left: -#{$toaster-width / 2};
    }
}
</style>