<template>
  <div class="three-content">
    <div class="scene">
      <canvas class="webgl"></canvas>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

export default {
  data() {
    return {
      speed: 0,
      bloomStrength: 0.8,
      bloomThreshold: 0.24,
      bloomRadius: 0.5,
      sphereColor: 0xfff0ba,
      backgroundColor: 0x464646,
    };
  },
  mounted() {
    const canvas = document.querySelector("canvas.webgl");

    const scene = new THREE.Scene();

    let sphereGeometry = new THREE.SphereGeometry(1, 20, 20);
    let sphereMaterial = new THREE.MeshBasicMaterial({
      color: this.sphereColor,
    });

    let sphereArray = [];
    for (let i = 0; i < 5; i++) {
      sphereArray[i] = new THREE.Mesh(sphereGeometry, sphereMaterial);
      let positionY = 5 * i - 10;
      sphereArray[i].position.y = positionY;
      scene.add(sphereArray[i]);
    }

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setClearColor(this.backgroundColor);

    const renderTarget = new THREE.WebGLRenderTarget(800, 600, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      encoding: THREE.sRGBEncoding,
    });

    const composer = new EffectComposer(renderer, renderTarget);
    composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    composer.setSize(sizes.width, sizes.height);

    window.addEventListener("resize", () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      composer.setSize(sizes.width, sizes.height);
    });

    const camera = new THREE.PerspectiveCamera(
      45,
      sizes.width / sizes.height,
      0.1,
      1000
    );
    camera.position.set(5, 0, 40);
    scene.add(camera);

    const renderScene = new RenderPass(scene, camera);
    composer.addPass(renderScene);

    const unrealBloomPass = new UnrealBloomPass();
    unrealBloomPass.strength = this.bloomStrength;
    unrealBloomPass.radius = this.bloomRadius;
    unrealBloomPass.threshold = this.bloomThreshold;
    composer.addPass(unrealBloomPass);

    const clock = new THREE.Clock();

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      sphereArray[0].position.x = Math.sin(elapsedTime + 2) * 14 + 3;
      sphereArray[1].position.x = Math.cos(elapsedTime + 4) * 14 - 1;
      sphereArray[2].position.x = Math.sin(elapsedTime + 1) * 14 + 2;
      sphereArray[3].position.x = Math.cos(elapsedTime + 0) * 14 - 4;
      sphereArray[4].position.x = Math.sin(elapsedTime + 5) * 14 + 5;
      sphereArray[0].position.z = Math.cos(elapsedTime + 2) * 14 + 20;
      sphereArray[1].position.z = Math.sin(elapsedTime + 4) * 14 + 20;
      sphereArray[2].position.z = Math.cos(elapsedTime + 1) * 14 + 20;
      sphereArray[3].position.z = Math.sin(elapsedTime + 0) * 14 + 20;
      sphereArray[4].position.z = Math.cos(elapsedTime + 5) * 14 + 20;

      composer.render();

      window.requestAnimationFrame(tick);
    };

    tick();
  },
};
</script>

<style lang="scss" scoped>
$break-small: 900px;

.three-content {
  position: fixed;
  width: calc(100% - var(--panel-width));
  height: 100%;
  background: linear-gradient(#e8e8e8, #ffffff);
  right: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  .scene {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #eee;
    mask: url(../../assets/frames-3.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 80% 80%;
  }
  @media screen and (max-width: $break-small) {
    display: none;
  }
}
</style>
