'use strict';
import axios from 'axios';
const POST_MESSAGE_URL = 'https://us-central1-bixell.cloudfunctions.net/api/v2/message';

export default {
    send: async (message) => {
        console.log(message)
        const response = await axios.post(POST_MESSAGE_URL, message);
        return response.data;
    }
}