<template>
    <v-touch v-on:swipedown="toggleContactCard">
        <div class="contact" :class="{'contact-active' : isCardOpen}">
            <div 
            class="contact__overlay" 
            :class="{'contact__overlay-active' : isCardOpen}"
            @click="toggleContactCard"
            ></div>
            <div class="contact__card" :class="{'contact__card-active' : isCardOpen}">
                <div class="contact__card-content">
                    <div @click="toggleContactCard" class="contact__card-title pointer">
                        <i class="fa fa-arrow-circle-o-left hide-mobile" aria-hidden="true"></i>
                        Contact
                    </div>
                    <input v-model="user.name" type="text" placeholder="Name">
                    <input v-model="user.email" type="email" placeholder="Email">
                    <textarea v-model="user.message"  cols="30" rows="10" placeholder="Message"></textarea>
                    <button @click="submit">Submit <Loading v-if="isLoading"/></button>
                    <div class="contact__or">OR</div>
                    <div class="contact__card-info">
                        Email our project manager, Brandon Cavilee, at <strong>brandon@bixell.co</strong>
                    </div>
                </div>
            </div>
        </div> 
    </v-touch>
</template>

<script>
import postMessage from '@/services/postMessage';
import Loading from '@/components/svg/Loading';

    export default {
        props: ['isCardOpen'],
        data() {
            return {
                isLoading: false,
                user: {
                    name: '',
                    email: '',
                    message: ''
                }
            }
        },
        methods: {
            toggleContactCard() {
                this.$emit("open-contact-card");
            },
            async submit() {
                
                if (!this.user.name.length || !this.user.email.length) {

                    this.$emit("post-message", {
                        isActive: true,
                        message: "Please fill in your name and email."
                    });
                    return;
                }
                this.isLoading = true;

                let response;

                try {
                    response = await postMessage.send(this.user);
                    if (response) {
                        this.$emit("post-message", {
                            isActive: true,
                            message: "Message sent"
                        });
                        this.toggleContactCard();
                    }
                } catch (error) {
                    console.log(error);
                    this.$emit("post-message", {
                        isActive: true,
                        message: "Couldn't send message. Please try again later."
                    });
                    
                } finally {
                    this.isLoading = false;
                }
            }
        },
        components: {
            Loading
        }
    }
</script>

<style lang="scss" scoped>
$break-small: 900px;

.contact {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: transparent;
    z-index: 4;
    pointer-events: none;

    &__overlay {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: transparent;
        pointer-events: none;
        transition: .35s all ease-in-out;
    }
    &__overlay-active {
        pointer-events: unset;
        background-color: transparent;
    }
    &__card {
        pointer-events: unset;
        position: absolute;
        background-color: #fff;
        width: var(--panel-width);
        height: 100%;
        left: calc(var(--panel-width) * -1);
        bottom: 0;
        z-index: 5;
        transition: .35s all ease-in-out;
        overflow: auto;
    }
    &__card-active {
        left: 0;
    }
    &__card-title {
        font-size: 28px;
        width: 100%;
        margin: 40px 0 30px 0;
        font-family: 'Roboto';
        font-weight: 700;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 65%;
            height: 2px;
            top: 50%;
            right: 0;
            background-color: #000;
        }
    }
    &__card-content {
        input, textarea {
            margin: 0 0 20px 0;
            border: 0;
            font-size: 18px;
        }
        transform: scale(.9);
        width: 95%;
        margin: 20px auto 0 auto;
    }
    &__or {
        font-size: 22px;
        width: 100%;
        margin: 40px 0 30px 0;
        font-family: 'Roboto';
        font-weight: 100;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 85%;
            height: 2px;
            top: 50%;
            right: 0;
            background-color: #000;
        }
    }
    &__card-info {
        font-size: 22px;
        width: 100%;
        margin: 30px 0 100px 0;
        font-family: 'Roboto';
        line-height: 1.2;
    }
    textarea {
        padding-top: 20px;
        height: 100px;
    }
    button {
        font-size: 18px;
    }
    @media screen and (max-width: $break-small) {
        &__overlay {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: transparent;
            pointer-events: none;
            transition: .35s all ease-in-out;
        }
        &__overlay-active {
            pointer-events: unset;
            background-color: rgba(100, 100, 100, 0.5);
        }
        &__card {
            pointer-events: unset;
            position: absolute;
            background-color: #fff;
            width: 100%;
            height: 85%;
            left: 0;
            bottom: -100%;
            z-index: 5;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            transition: .35s all ease-in-out;
            overflow: auto;
        }
        &__card-title {
            margin-top: 0;
        }
        &__card-active {
            bottom: 0;
        }
    }
}
.contact-active {
    pointer-events: unset;
}
</style>