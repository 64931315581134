<template>
    <div class="lines">
        <svg  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path shape-rendering="geometricPrecision" d="M0.5 1L329.5 23" stroke="black" stroke-width="2"/>
            <path shape-rendering="geometricPrecision" d="M229.5 30.5L326 1" stroke="black" stroke-width="2"/>
        </svg>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.lines {
    width: 100%;
    svg {
        position: relative;
        z-index: 3;
        margin: 0 auto;
        width: 100%;
        height: 40px;
    }
}

</style>